<template>
  <div class="checkList">
    <Scroll class="region-scroll">
      <!-- 检查详情 -->
      <div class="checkuser">
        <div class="checkInfo">
          <div class="checkInfoinner">
            <div class="front">就诊人:</div>
            <div class="info">{{$store.state.cardList[0].name}}</div>
          </div>
          <div class="checkInfoinner">
            <div class="front">检查名称:</div>
            <div class="info">{{obj.jcxm}}</div>
          </div>
          <div class="checkInfoinner">
            <div class="front">检查科室:</div>
            <div class="info">{{obj.kdksmc}}</div>
          </div>
          <div class="checkInfoinner">
            <div class="front">报告医生:</div>
            <div class="info">{{obj.emp_name_book}}</div>
          </div>
          <div class="checkInfoinner">
            <div class="front">开单时间：</div>
            <div class="info">{{obj.kdsj}}</div>
          </div>
          <div class="checkInfoinner">
            <div class="front">报告时间：</div>
            <div class="info">{{obj.book_time}}</div>
          </div>
        </div>
      </div>
      <div class="lookup small-size">可查报告类型：放射、超声、检验、病理</div>
      <div class="performance">
        <div class="performancetitle">诊断表现</div>
        <div class="performancecontent">{{obj.examine_show}}</div>
      </div>
      <div class="performance">
        <div class="performancetitle">诊断结果</div>
        <div class="performancecontent">{{obj.examine_result}}</div>
      </div>
      <div class="tips">
        <div class="tipstitle">检查提示</div>
        <div class="tipscontent">请结合临床</div>
      </div>
    </Scroll>
  </div>
</template>

<script>
import Scroll from '@/components/content/scroll/BScroll'
export default {
  name: "checkList",
  components: {
    Scroll
  },
  data () {
    return {
      obj: this.$route.params.obj,
    }
  },
}
</script>

<style lang="less" scoped>
.region-scroll {
  height: 100vh;
}
.checkList {
  height: 100vh;
  background-color: #f5f5f5;
  .checkuser {
    padding-left: 0.7rem;
    background: @cardColor;
    .checkInfo {
      display: flex;
      flex-direction: column;
      .checkInfoinner {
        display: flex;
        flex: 1;
        height: 2rem;
        line-height: 2rem;
        .front {
          flex: 0.3;
        }
        .info {
          flex: 0.7;
          white-space: nowrap;
        }
      }
    }
  }
  .lookup {
    background: rgb(255, 255, 255);
    padding-left: 0.7rem;
    height: 2rem;
    line-height: 2rem;
    color: #c8c8c8;
  }
  .performance {
    margin-top: 0.5rem;
    background: rgb(255, 255, 255);
    padding: 0.5rem 0.7rem;
    .performancetitle {
      height: 2rem;
      line-height: 2rem;
      color: #c8c8c8;
    }
    .performancecontent {
      line-height: 1.6rem;
    }
  }
  .tips {
    height: 5rem;
    margin-top: 1rem;
    background: rgb(255, 255, 255);
    padding-left: 0.7rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    .tipstitle {
      color: #c8c8c8;
    }
  }
}
</style>
